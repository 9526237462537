import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
   

   <path d="M917 2526 c-95 -34 -167 -115 -187 -209 -8 -36 -10 -217 -8 -587 3
-599 0 -569 78 -654 22 -24 62 -55 88 -68 l47 -23 565 0 565 0 49 24 c58 29
108 77 138 134 l23 42 0 575 0 575 -30 53 c-32 59 -66 91 -129 125 -41 22 -45
22 -601 24 -454 2 -567 0 -598 -11z m1189 -81 c30 -19 60 -49 79 -79 l30 -49
3 -530 c3 -597 3 -603 -65 -671 -68 -68 -47 -66 -650 -66 -469 0 -549 2 -581
16 -47 19 -88 58 -113 106 -18 35 -19 66 -19 586 0 610 -2 590 67 657 66 64
68 64 664 62 l536 -2 49 -30z m131 -447 c-1 -57 -3 -11 -3 102 0 113 2 159 3
103 2 -57 2 -149 0 -205z m-1470 0 c-1 -46 -3 -9 -3 82 0 91 2 128 3 83 2 -46
2 -120 0 -165z m1470 -685 c-1 -60 -3 -11 -3 107 0 118 2 167 3 108 2 -60 2
-156 0 -215z"/>
<path d="M1403 2169 c-61 -52 -340 -327 -359 -354 -8 -11 -14 -37 -14 -58 0
-35 11 -49 187 -233 103 -108 195 -200 205 -205 41 -22 86 -10 140 37 69 61
336 323 354 349 8 11 14 37 14 58 0 36 -11 49 -176 223 -97 101 -188 193 -202
204 -42 34 -93 27 -149 -21z m306 -179 c99 -103 185 -199 190 -212 6 -15 5
-33 -3 -50 -6 -15 -95 -108 -198 -207 -176 -170 -190 -181 -225 -181 -36 0
-48 10 -225 195 -171 176 -188 197 -188 229 0 41 -7 34 216 249 164 158 192
179 236 169 9 -2 97 -88 197 -192z"/>
<path d="M1556 1959 c-51 -12 -51 -12 -31 -43 13 -19 16 -39 12 -74 -7 -62 4
-78 55 -86 55 -8 73 -20 64 -41 -5 -14 -16 -16 -56 -13 -46 3 -51 1 -57 -20
-3 -12 -13 -26 -22 -31 -27 -16 -5 -23 71 -24 110 0 158 31 158 103 0 60 -26
79 -147 110 -32 8 -43 16 -43 30 0 17 7 20 40 20 23 0 54 -7 70 -15 29 -15 30
-15 49 23 l20 38 -32 11 c-51 18 -109 22 -151 12z m121 -40 c-1 -17 -6 -20
-30 -15 -16 3 -40 6 -53 6 -13 0 -24 3 -24 8 0 4 21 5 46 3 32 -2 44 0 40 8
-4 6 -1 11 8 11 9 0 14 -8 13 -21z m2 -122 c20 -9 29 -17 22 -21 -15 -10 -2
-29 15 -22 10 4 14 -4 14 -31 0 -27 -3 -34 -11 -26 -14 14 -48 -15 -41 -34 3
-9 -7 -13 -32 -13 -45 0 -48 21 -6 37 23 9 30 18 30 37 0 14 -1 26 -2 26 -2 0
-28 9 -58 20 l-55 20 47 -3 c53 -2 55 8 3 17 -19 4 -37 10 -39 15 -7 11 70 -4
113 -22z"/>
<path d="M1275 1938 c-12 -41 -59 -185 -62 -195 -4 -9 2 -9 26 0 34 13 41 27
14 27 -12 0 -14 3 -6 8 6 4 11 16 10 27 -1 11 3 19 10 18 9 -2 11 6 7 25 -4
19 -1 32 10 41 9 8 16 20 16 28 0 11 16 13 78 11 65 -3 77 -5 74 -20 -1 -9 6
-19 17 -23 14 -4 19 -14 17 -33 -1 -15 -7 -26 -13 -25 -12 3 -6 -35 9 -53 4
-6 5 -20 1 -29 -4 -13 -3 -16 5 -11 8 5 12 0 12 -14 0 -12 -3 -19 -7 -17 -9 4
-43 -28 -43 -40 0 -5 -20 -9 -45 -11 -38 -3 -44 -1 -39 13 5 12 10 13 17 6 14
-14 27 -14 27 0 0 15 26 39 34 32 3 -4 6 5 6 20 0 14 -6 32 -14 40 -14 14 -12
48 4 72 9 13 7 26 -8 53 -9 16 -22 22 -47 22 -30 0 -37 -5 -55 -40 -23 -44
-25 -67 -8 -72 7 -3 5 -13 -7 -32 -11 -17 -21 -23 -25 -16 -5 8 -10 7 -17 -2
-11 -14 -48 -157 -41 -163 2 -2 17 22 33 53 16 31 44 83 62 115 18 33 33 61
33 64 0 2 -10 1 -21 -2 -12 -4 -19 -3 -17 1 2 5 10 24 17 42 10 25 19 32 41
32 50 0 67 -55 21 -67 -15 -4 -41 -40 -41 -58 0 -2 15 -5 33 -7 27 -2 32 -7
32 -28 0 -22 -5 -26 -48 -30 -40 -4 -50 -10 -67 -37 l-20 -33 86 0 c81 0 87 1
115 29 34 35 38 77 10 112 -17 22 -17 27 -5 44 23 31 17 73 -15 106 -28 28
-34 29 -116 29 -60 0 -87 -4 -90 -12z"/>
<path d="M1093 890 c-12 -5 -30 -21 -39 -35 -26 -40 -10 -75 46 -96 55 -20 67
-44 19 -37 -19 3 -42 8 -51 12 -11 5 -20 1 -28 -15 -11 -19 -10 -25 5 -36 42
-30 118 -28 149 3 44 44 24 95 -46 116 -21 6 -38 17 -38 25 0 15 19 17 54 3
21 -8 27 -6 36 10 16 31 12 38 -27 49 -44 13 -51 13 -80 1z"/>
<path d="M1303 893 c-24 -5 -63 -47 -63 -68 0 -23 37 -59 71 -70 18 -6 35 -17
37 -23 5 -16 -43 -16 -68 0 -16 11 -22 9 -34 -7 -21 -28 -20 -31 18 -49 42
-20 79 -20 115 -1 69 35 47 114 -35 130 -20 3 -34 12 -34 20 0 17 18 19 54 6
20 -8 27 -6 40 14 15 22 15 25 -2 33 -24 13 -73 20 -99 15z"/>
<path d="M1930 883 c-29 -15 -36 -24 -38 -54 -3 -39 14 -60 62 -76 17 -5 32
-15 34 -21 5 -15 -32 -16 -59 -1 -23 12 -49 -3 -49 -27 0 -23 59 -47 102 -41
51 7 78 32 78 72 0 37 -12 50 -63 68 -20 6 -35 17 -34 22 2 11 34 12 58 1 11
-5 19 1 27 18 10 22 9 27 -6 35 -38 20 -77 21 -112 4z"/>
<path d="M428 777 l3 -114 66 3 c85 5 98 9 111 39 13 28 2 70 -20 78 -10 4
-11 7 0 18 19 20 15 67 -7 79 -11 5 -50 10 -88 10 l-68 0 3 -113z m110 46 c-2
-10 -13 -19 -26 -21 -17 -3 -22 2 -22 17 0 16 6 21 26 21 19 0 25 -5 22 -17z
m10 -85 c3 -14 -3 -18 -27 -18 -25 0 -31 4 -31 21 0 16 5 20 27 17 17 -2 29
-10 31 -20z"/>
<path d="M646 778 l2 -112 83 0 84 0 0 30 c0 29 -1 29 -52 28 l-53 -2 0 84 0
84 -32 0 -33 0 1 -112z"/>
<path d="M840 778 l0 -113 85 0 85 0 0 27 c0 27 -2 28 -55 28 -42 0 -55 3 -55
15 0 11 12 15 50 15 49 0 50 1 50 30 0 29 -1 30 -51 30 -36 0 -49 4 -46 13 3
8 22 11 56 10 50 -2 51 -2 51 27 l0 30 -85 0 -85 0 0 -112z"/>
<path d="M1450 778 l0 -113 85 0 85 0 0 27 c0 27 -2 28 -55 28 -42 0 -55 3
-55 15 0 11 12 15 50 15 49 0 50 1 50 30 0 29 -1 30 -50 30 -39 0 -50 3 -50
16 0 14 8 15 55 9 l55 -6 0 30 0 31 -85 0 -85 0 0 -112z"/>
<path d="M1650 774 l0 -116 31 6 c17 3 52 6 76 6 36 0 50 6 74 29 25 25 29 37
29 81 0 44 -4 56 -29 81 -27 27 -35 29 -105 29 l-76 0 0 -116z m142 31 c16
-36 -2 -71 -40 -77 -30 -4 -30 -4 -34 49 l-3 53 33 0 c25 0 35 -6 44 -25z"/>
<path d="M2090 806 c0 -69 4 -88 20 -109 36 -45 110 -47 150 -4 17 17 20 35
20 109 l0 88 -30 0 -30 0 0 -74 c0 -81 -16 -110 -48 -89 -14 8 -17 26 -17 87
l0 76 -32 0 -33 0 0 -84z"/>
<path d="M2320 776 l0 -114 78 5 c48 4 84 11 95 21 24 21 22 67 -5 92 -12 11
-17 20 -10 20 21 0 26 49 7 70 -15 16 -31 20 -92 20 l-73 0 0 -114z m120 50
c0 -14 -21 -26 -46 -26 -8 0 -14 9 -14 20 0 16 7 20 30 20 19 0 30 -5 30 -14z
m5 -86 c0 -10 -12 -16 -32 -18 -28 -3 -33 0 -33 18 0 18 5 21 33 18 20 -2 32
-8 32 -18z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
